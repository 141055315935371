<template>
  <li
    v-if="message"
    class="
      bg-gray-100
      dark:bg-gray-800
      px-4
      py-6
      shadow
      sm:p-6 sm:rounded-lg
      border-l-4
    "
    :class="
      message.is_urgent
        ? 'border-red-400 dark:border-red-400'
        : 'border-transparent'
    "
  >
    <article aria-labelledby="question-title-81614">
      <div>
        <div class="flex space-x-3 mb-5">
          <div class="flex-shrink-0">
            <template v-if="message.author.id === 1">
              <img
                class="h-12 w-12 rounded-full"
                src="https://ih1.redbubble.net/image.1792143409.0562/poster,504x498,f8f8f8-pad,600x600,f8f8f8.jpg"
                alt=""
              />
            </template>
            <template v-else>
              <div class="h-full flex items-center">
                <div
                  class="
                    w-12
                    h-12
                    bg-gray-400
                    rounded-full
                    flex
                    justify-center
                    align-center
                    text-white
                  "
                >
                  <span class="text-2xl leading-[48px] font-semibold">{{
                    message.author.avatar
                  }}</span>
                </div>
              </div>
            </template>
          </div>
          <div class="min-w-0 flex-1">
            <p class="text-sm font-medium text-gray-900 dark:text-gray-200">
              <span>{{ message.author.name }}</span>
            </p>
            <p class="text-sm text-gray-500 dark:text-gray-400">
              <span>
                <time datetime="2020-12-09T11:43:00">{{
                  message.created_at
                }}</time>
              </span>
            </p>
          </div>

          <template v-if="message.allow_edit">
            <div class="flex-shrink-0 self-center flex">
              <div class="relative inline-block text-left">
                <div class="flex">
                  <template v-if="editableItem.id !== message.id">
                    <Button
                      icon="pencil"
                      @click="$store.dispatch('setEditableItem', message)"
                    />
                    <Button icon="delete" @click="deleteMessage(message)" />
                  </template>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="mt-2 text-base text-gray-700 dark:text-gray-300 space-y-4">
        <template v-if="editableItem.id !== message.id">
          <p>
            {{ message.text }}
          </p>
        </template>
        <template v-if="editableItem.id === message.id">
          <EditNews :component="component" />
        </template>
      </div>
    </article>
  </li>
</template>

<script>
import { mapGetters } from "vuex";
import EditNews from "@/components/Home/News/EditNews";

export default {
  name: "ShowMessageListItem",
  components: {
    EditNews,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    component: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      editableItem: "editableItem",
    }),
  },
  methods: {
    deleteMessage(message) {
      if (confirm("Vai esi drošs?")) {
        this.$Progress.start();
        this.$store.dispatch("deleteMessage", {id: this.component.id, data: message});
      }
    },
  },
};
</script>

<style>
</style>