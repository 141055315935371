<template>
  <form class="w-full">
    <div class="grid grid-cols-1">
      <div class="col-span-1" style="height: 100px">
        <Textarea
          placeholder="Ziņa"
          v-model="editableItem.text"
          :errors="errors.text"
          :showPlaceholder="false"
        />
      </div>
      <div class="col-span-1">
        <Checkbox
          class="md:mt-8"
          text="Svarīga ziņa"
          v-model="editableItem.is_urgent"
        />
      </div>
    </div>

    <div
      class="
        md:flex-shrink-0
        flex
        items-center
        flex-wrap
        ml-auto
        gap-3
        justify-end
        pt-4
      "
    >
      <template v-if="!loading">
        <button
          type="button"
          class="
            flex
            justify-center
            py-2
            px-4
            border border-transparent
            rounded-md
            shadow-sm
            text-sm
            font-medium
            text-white
            bg-gradient-to-r
            from-button2-from
            to-button2-to
            hover:from-button2-to hover:to-button2-from
            focus:outline-none focus:border-none
            transition
            duration-500
            ease-in-out
          "
          @click.prevent="submit"
        >
          Saglabāt
        </button>
      </template>
      <template v-else>
        <Loading />
      </template>

      <button
        @click.prevent="$store.dispatch('clearEditableItem')"
        type="button"
        class="
          inline-flex
          items-center
          px-4
          py-2
          border border-gray-300
          dark:border-gray-500
          shadow-sm
          text-sm
          font-medium
          rounded-md
          text-gray-700
          dark:text-gray-300
          bg-white
          dark:bg-gray-750
          hover:bg-gray-50
          dark:hover:bg-gray-770
          focus:outline-none focus:ring-0
        "
      >
        Atcelt
      </button>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import Textarea from "@/components/Components/Textarea";
import Checkbox from "@/components/Components/Checkbox";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";

export default {
  name: "EditMessage",
  components: {
    Textarea,
    Checkbox,
    Loading,
  },
  props: {
      component: {
          type: Object
      }
  },
  computed: {
    ...mapGetters({
      editableItem: "editableItem",
      errors: "errors",
      loading: "loading",
    }),
    formValidation() {
      return {
        text: {
          rules: ["required"],
        },
      };
    },
  },
  methods: {
    submit() {
      this.$Progress.start();
      if (Validation(this.formValidation, this.editableItem)) {
        this.$store.dispatch("updateMessage", {id: this.component.id, data: this.editableItem});
      } else {
        this.$Progress.fail();
      }
    },
  },
};
</script>
<style scoped>
</style>